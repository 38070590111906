<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode, 'not-found-page': true }">
    <BCard header-tag="header" footer-tag="footer" class="text-center not-found-card">
      <template #header>
        <BIconExclamationTriangle variant="danger" /> <strong>페이지를 찾을 수 없습니다</strong>
      </template>
      
      <BRow class="my-4">
        <BCol>
          <h1 class="display-1">404</h1>
          <p class="lead">요청하신 페이지를 찾을 수 없습니다.</p>
          <BButton variant="primary" @click="goHome">홈으로 돌아가기</BButton>
        </BCol>
      </BRow>
      
      <template #footer>
        <small class="text-muted">현재 URL이 잘못되었거나 페이지가 존재하지 않습니다.</small>
      </template>
    </BCard>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
  methods: {
    goHome() {
      this.$router.push({ name: 'Dashboard' });
    }
  }
}
</script>

<style scoped>
.not-found-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #121212;
}

.not-found-card {
  max-width: 600px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Darker shadow for dark mode */
  background-color: #1e1e1e; /* Dark background for the card */
  color: #ffffff; /* Light text color for contrast */
}

.display-1 {
  font-size: 6rem;
  color: #ff6b6b; /* Bright color for 404 */
}

.lead {
  font-size: 1.5rem;
  color: #cccccc; /* Light gray for lead text */
}

.text-muted {
  font-size: 1rem;
  color: #888888; /* Muted gray for less important text */
}

.b-card header, .b-card footer {
  background-color: #1e1e1e; /* Same dark background for header and footer */
  color: #ffffff; /* Light text color */
}

.b-card header {
  border-bottom: 1px solid #333333; /* Slightly lighter border for header */
}

.b-card footer {
  border-top: 1px solid #333333; /* Slightly lighter border for footer */
}

.b-button {
  color: #ffffff; /* Ensure button text is light */
}
</style>
